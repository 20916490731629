var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"q-pb-lg"},[_c('q-form',{ref:"stepOneForm",on:{"submit":function($event){return _vm.stepOneSubmit()}}},[_c('div',{staticClass:"row justify-center q-gutter-y-md"},[_c('q-card',{staticClass:"col-md-7 col-12 full-height q-my-lg"},[_c('q-item',{staticClass:"bg-secondary"},[_c('q-item-section',{staticClass:"text-center"},[_c('q-item-label',{staticClass:"card-title q-py-xs"},[_vm._v(_vm._s(_vm.$t("request.service")))])],1)],1),_c('q-item',[_c('q-item-section',{staticClass:"text-center q-pb-sm"},[_c('q-select',{ref:"select",attrs:{"outlined":"","map-options":"","use-input":"","hide-selected":"","fill-input":"","clearable":"","color":"accent","label":_vm.$t('request.service_search'),"option-value":"id","option-label":"nombre","options":_vm.options_services,"options-dense":true,"rules":[_vm.formSelectOrType]},on:{"filter":_vm.filterService,"input":_vm.selectService},scopedSlots:_vm._u([{key:"option",fn:function(scope){return [_c('q-item',_vm._g(_vm._b({},'q-item',scope.itemProps,false),scope.itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(
                        _vm.boldCoincidence(scope.opt.nombre, _vm.filter_string)
                      )}})],1)],1)]}},{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"accent","name":"search"}})]},proxy:true},{key:"no-option",fn:function(){return [_c('div',{staticClass:"q-my-md q-pl-sm"},[_vm._v(" No se encontraron coincidencias ")])]},proxy:true}]),model:{value:(_vm.service_selected),callback:function ($$v) {_vm.service_selected=$$v},expression:"service_selected"}})],1)],1),_c('q-item',[_c('q-item-section',{staticClass:"text-left"},[_c('q-list',{staticClass:"row rounded-borders q-py-none",attrs:{"bordered":"","padding":""}},_vm._l((_vm.categorias),function(categoria){return _c('q-expansion-item',{key:categoria.id,staticClass:"col-12 category-item",attrs:{"label":categoria.nombre,"id":'expansion-' + categoria.id,"group":"string","dense":"","dense-toggle":""},on:{"show":function($event){return _vm.scrollToCategory(categoria.id)}}},[_c('q-list',_vm._l((_vm.services.filter(
                      v => v.id_categoria === categoria.id
                    )),function(service){return _c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:service.id,staticClass:"item-service",class:_vm.service_selected && _vm.service_selected.id == service.id
                        ? 'highlighted-item'
                        : '',attrs:{"clickable":""},on:{"click":function($event){return _vm.selectService(service)}}},[_c('q-item-section',{staticClass:"text-left"},[_vm._v(_vm._s(service.nombre))])],1)}),1)],1)}),1)],1)],1)],1)],1)]),_c('DialogService',{ref:"DialogService",attrs:{"service_selected":_vm.service_selected ? _vm.service_selected : ''},on:{"moreHelp":_vm.moreHelpRequested}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }