
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogService extends Vue {
  @Prop({ default: 0 }) readonly service_selected!: any;
  dialog = false;

  private askMoreHelp() {
    this.$emit("moreHelp", true);
    this.dialog = false;
  }
}
